var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    {
      staticStyle: {
        position: "absolute",
        top: "60px",
        left: "0",
        width: "100%",
        overflow: "hidden",
      },
      attrs: { tip: _vm.title, spinning: _vm.params.isDownload == "true" },
    },
    [
      _c("iframe", {
        staticClass: "mytasks_iframe",
        style: { width: "100%", height: `calc(100vh - 71px)` },
        attrs: {
          id: "mytasks_iframe",
          src: `//${_vm.host}/editor/${_vm.params.taskId}/${_vm.params.productionId}/${_vm.params.chapterId}/${_vm.params.platformId}?productionName=${_vm.params.productionName}&isDownload=${_vm.params.isDownload}&targetLang=${_vm.params.targetLang}`,
          frameborder: "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }