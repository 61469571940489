<template>
  <a-spin
    :tip="title"
    :spinning="params.isDownload == 'true'"
    style="position: absolute;top:60px;left:0;width:100%;overflow:hidden"
  >
    <iframe
      :style="{ width: '100%', height: `calc(100vh - 71px)` }"
      class="mytasks_iframe"
      id="mytasks_iframe"
      :src="
        `//${host}/editor/${params.taskId}/${params.productionId}/${params.chapterId}/${params.platformId}?productionName=${params.productionName}&isDownload=${params.isDownload}&targetLang=${params.targetLang}`
      "
      frameborder="0"
    ></iframe>
  </a-spin>
</template>

<script>
// :src="`//47.105.113.138/editor/${params.productionId}/${params.chapterId}`"
export default {
  components: {},
  data() {
    return {
      params: {
        ...this.$route.params,
        ...this.$route.query
      },
      iframeHeight: 0,
      title: '自动上传中，请不要关闭页面...',
      host: this.VUE_APP_TRANSLATE_URL
    }
  },
  methods: {
    getChild(data) {
      console.log('获取到子组件数据', data)
    }
  },
  mounted() {
    let mytasksIframeDom = document.getElementById('mytasks_iframe')

    // mytasksIframeDom.onload = function() {
    let headerDom = document.getElementsByClassName('header')[0]
    let headerHeight = 0
    if (headerDom) {
      headerHeight = headerDom.offsetHeight
    }

    // console.log(headerHeight,"headerHeight")
    // this.iframeHeight = window.innerHeight - headerHeight - 5 // 18是外层min的margin12 + 外层的黑度6

    if (mytasksIframeDom) {
      mytasksIframeDom.parentElement.style.margin = '0'
    }
    // }
    if (this.params.isDownload == 'true') {
      let iframe = document.getElementById('mytasks_iframe')
      iframe.contentWindow.getChild = this.getChild()
      setTimeout(() => {
        window.close()
/*         setInterval(() => {
          console.log(document.getElementById('mytasks_iframe'))
        }, 500) */
      }, 8000 * this.params.pageNum+4000)
      setTimeout(() => {
        this.title = '文件压缩中，下载完成后自动关闭页面...'
      }, 8000 * this.params.pageNum + 2000)
    }
  }
}
</script>

<style lang="less">
.mytasks_iframe {
  width: 100%;
  overflow: hidden;
}
</style>
