import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=79e39088&"
import script from "./Editor.vue?vue&type=script&lang=js&"
export * from "./Editor.vue?vue&type=script&lang=js&"
import style0 from "./Editor.vue?vue&type=style&index=0&id=79e39088&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79e39088')) {
      api.createRecord('79e39088', component.options)
    } else {
      api.reload('79e39088', component.options)
    }
    module.hot.accept("./Editor.vue?vue&type=template&id=79e39088&", function () {
      api.rerender('79e39088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/translate/Editor.vue"
export default component.exports